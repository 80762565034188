import React, { useEffect, useState } from "react";
import AdminLayout from "../../AdminStructure/AdminLayout";
import DataTable from "../../../../Component-CSS/DataTable/DataTable";
import { FiPackage, FiSettings, FiUser } from "react-icons/fi";
import TabsComponent from "../../../../Component-CSS/Tabs/TabsComponent";
import {
  amClassOptions,
  pmClassOptions,
  rolesOptions,
} from "../../../../Models/EnumHelper";
import axiosInstance from "../../../../Services/Interceptors/axiosInstance";
import { userDataHeader } from "../../../../Constant/TableHeaders";

const PmClass: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);
  const fetchUserData = async () => {
    try {
      const results: any = await axiosInstance.get("/api/User/GetUsers");
      const transformedData =
        Array.isArray(results.data) &&
        results.data.map((user: any) => {
          const { year, id, createdOn, qrCodeNumber, ...rest } = user;
          return {
            ...rest,
            qrCodeNumber,
            createdOn:
              new Date(createdOn).toLocaleString(),
            roleID:
              rolesOptions.find((option) => option.value === user.roleID)
                ?.label || user.roleID,
            amClassID:
              amClassOptions.find((option) => option.value === user.amClassID)
                ?.label || user.amClassID,
            pmClassID:
              pmClassOptions.find((option) => option.value === user.pmClassID)
                ?.label || user.pmClassID,
          };
        });
      setUserData(transformedData);
    } catch (error) {
      console.error(`Error fetching data from API:`, error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [activeTab, setActiveTab] = useState("Only Expo");
  const filteredUsers = userData.filter((user) => user.pmClassID === activeTab);

  const tabs = [
    {
      id: "Only Expo",
      label: "Only Expo",
      icon: FiPackage,
      component: (
        <DataTable
          users={filteredUsers}
          recordsData={250}
          header={userDataHeader}
        />
      ),
    },
    {
      id: "Medical Error PM",
      label: "Medical Error PM",
      icon: FiUser,
      component: (
        <DataTable
          users={filteredUsers}
          recordsData={10}
          header={userDataHeader}
        />
      ),
    },
    {
      id: "Opioid Prescribing",
      label: "Opioid Prescribing",
      icon: FiSettings,
      component: (
        <DataTable
          users={filteredUsers}
          recordsData={10}
          header={userDataHeader}
        />
      ),
    },
  ];

  return (
    <AdminLayout title="PM Class">
      <TabsComponent
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </AdminLayout>
  );
};

export default PmClass;
