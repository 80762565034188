import React, {useState, useEffect} from "react";
import HeaderComponent from "../Component-CSS/Header/HeaderComponent";
import FooterComponent from "../Component-CSS/Footer/FooterComponent";
import { adsDataProps, navItemsProps } from "../Models/types";
import { RoutesPath } from "../Constant/RoutesConstant";
import EventAds from "../Component-CSS/Advertisement/EventAds";
import logoImage from "../Assets/MedExpo_logo.jpeg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../Services/Interceptors/axiosInstance";



const MainLayouts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [buttonName, setButtonName] = useState('Register');
    useEffect(() => {
      fetchData();
    }, []); 

//The async function inside useEffect
const fetchData = async () => {
  const response = await axiosInstance.get("/api/User/GetTreeMenuStatusByName", {
    params: { vstrMenuName: "Registration" },  // Query parameter
  });
  if(response.data === true){
    setButtonName('Register');
  }else{
    setButtonName('Survey');
  }
}

  const navigate = useNavigate();
  const navItems: navItemsProps[] = [
    { navItem: "Home", link: RoutesPath.HOME },
    { navItem: "About Us", link: RoutesPath.ABOUT_US },
    { navItem: "Sponsors", link: RoutesPath.SPONSORS },
    { navItem: "Gallery", link: RoutesPath.GALLERY },
    { navItem: "Contact", link: RoutesPath.CONTACT },
    {
      navItem: "W9-Form",
      link: "https://annualmedicalexpo.com/Form%20W9%20-%20IACEC.pdf",
    },
    // { navItem: "Survey", link: RoutesPath.SURVEY },
  ];

  const ads: adsDataProps[] = [
    {
      title:
        "North Central Florida's Largest Medical Expo for Healthcare Professionals",
    },
    {
      title: "CME Class - Domestic Violence, Medical Error, Opioid Prescribing",
    },
    {
      title: "Great Food, Entertainment, and Opportunity to Network",
    },
    {
      title: "Chance to WIN Gift",
    },
    {
      title: buttonName === 'Register' ? "Registration Open Now" : "Registration has been closed. Looking forward to seeing you next year." ,
    },
  ];

  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex flex-col bg-sky-50">
      <div className="flex-grow">
        <HeaderComponent
          navItems={navItems}
          buttonLink={() => buttonName === 'Register' ?  navigate(RoutesPath.REGISTRATION) :navigate(RoutesPath.SURVEY)  }
          buttonName={buttonName}
          logoImage={logoImage}
        />
        <div className="hidden md:block text-center bg-white text-black font-semibold p-2 uppercase tracking-wide">
          north central florida's largest medical expo in ocala, florida!
        </div>
        <div className="text-center bg-red-700 text-white font-semibold p-2 tracking-wide">
          <EventAds adsData={ads} />
        </div>
        <div className="w-full">{children}</div>
      </div>
      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default MainLayouts;
