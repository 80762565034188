import React from "react";
import MainLayouts from "../../Layout/MainLayout";
//import Image
// {const localImages = [
//   require("https://annualmedicalexpo.com/images/image1.jpg"),
//   require("https://annualmedicalexpo.com/images/image2.jpg"),
//   require("https://annualmedicalexpo.com/images/image3.jpg"),
//   require("https://annualmedicalexpo.com/images/image4.jpg"),
//   require("https://annualmedicalexpo.com/images/image5.jpg"),
//   require("https://annualmedicalexpo.com/images/image6.jpg"),
//   require("https://annualmedicalexpo.com/images/image7.jpg"),
//   require("https://annualmedicalexpo.com/images/image8.jpg"),
//   require("https://annualmedicalexpo.com/images/image9.jpg"),
//   require("https://annualmedicalexpo.com/images/image10.jpg"),
//   require("https://annualmedicalexpo.com/images/image11.jpg"),
//   require("https://annualmedicalexpo.com/images/image12.jpg"),
// ];}

const Gallery: React.FC = () => {
  const images = [
    [
      "https://annualmedicalexpo.com/images/image1.jpg",
      "https://annualmedicalexpo.com/images/image2.jpg",
      "https://annualmedicalexpo.com/images/image3.jpg",
    ],
    [
      "https://annualmedicalexpo.com/images/image4.jpg",
      "https://annualmedicalexpo.com/images/image5.jpg",
      "https://annualmedicalexpo.com/images/image6.jpg",
    ],
    [
      "https://annualmedicalexpo.com/images/image7.jpg",
      "https://annualmedicalexpo.com/images/image8.jpg",
      "https://annualmedicalexpo.com/images/image9.jpg",
    ],
    [
      "https://annualmedicalexpo.com/images/image10.jpg",
      "https://annualmedicalexpo.com/images/image11.jpg",
      "https://annualmedicalexpo.com/images/image12.jpg",
    ],

    // [
    //   localImages[3],
    //   localImages[4],
    //   localImages[5],
    // ],
    
  ];

  return (
    <MainLayouts>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-10 mt-4">
        {images.map((items, index) => (
          <div key={index} className="grid gap-4">
            {items.map((src, index) => (
              <div key={index}>
                <img
                  className="h-auto max-w-full rounded-lg"
                  src={src}
                  alt={`Gallery image ${index + 1}`}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </MainLayouts>
  );
};

export default Gallery;
