import React from "react";
import { Link } from "react-router-dom";

const RegistrationConfirm: React.FC = () => {
  return (
    <div className="flex flex-col">
      <span className="text-green-600 mb-4 font-semibold text-lg">
        Thank you for registering for Expo. You will receive confirmation email
        shortly.
      </span>
      <p className="mb-4">
        Confirmation email is being send from&nbsp;
        <span className="font-semibold text-blue-600">
          annunalmedicalexpo@gmail.com.
        </span>
      </p>
      <p className="mb-4">
        If you do not receive the email in 15 minutes please check your
        Spam/Junk folder or Email us at&nbsp;
        <Link
          to="mailto:annunalmedicalexpo@gmail.com"
          className="font-semibold text-blue-600"
        >
          annunalmedicalexpo@gmail.com.
        </Link>
      </p>
      <p className="mb-4">
        You can also verify your registration using the following link:&nbsp;
        <a
          href="https://annualmedicalexpo.com/verify"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-blue-600 underline"
        >
          Verify Registration
        </a>
      </p>
    </div>
  );
};

export default RegistrationConfirm;
