import React, { useEffect, useState } from "react";
import ModalComponent from "../../../Component-CSS/Modal/ModalComponent";
import { editProps } from "../../../Models/types";
import UseFormikForm from "../../../Component-CSS/UseFormikForm/UseFormikForm";
import Dropdown from "../../../Component-CSS/Dropdown/Dropdown";
import InputFieldComponent from "../../../Component-CSS/Input/InputFieldComponent";
import { FaPenToSquare, FaPhoneFlip, FaUserPen } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import axiosInstance from "../../../Services/Interceptors/axiosInstance";
import * as Yup from "yup";
import { rolesOptions } from "../../../Models/EnumHelper";

const UserEdit: React.FC<editProps> = ({
  openModal,
  setShowPopUp,
  userData,
  onUpdate,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(openModal);
  const [amClassOptions, setAmClassOptions] = useState<any>([]);
  const [pmClassOptions, setPmClassOptions] = useState<any>([]);
  const [getData, setGetData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchClasses = async (endpoint: any, setOptions: any) => {
    try {
      const response = await axiosInstance.get(endpoint);
      const options = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (error) {
      console.error(`Error fetching classes from ${endpoint}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          id: userData.id,
          firstName: "string",
          lastName: "string",
          email: "string",
          phoneNumber: "string",
          roleID: 0,
          amClassID: 0,
          pmClassID: 0,
          year: 0,
          qrCodeNumber: 0,
        };
        const response = await axiosInstance.post(
          "/api/User/GetUserByID",
          payload
        );
        setGetData(response.data);

        await Promise.all([
          fetchClasses("/api/User/GetAMClasses", setAmClassOptions),
          fetchClasses("/api/User/GetPMClasses", setPmClassOptions),
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [userData.id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    firstName: getData?.firstName || "",
    lastName: getData?.lastName || "",
    email: getData?.email || "",
    phoneNumber: getData?.phoneNumber || "",
    amClassID:
      amClassOptions.find(
        (option: { value: any }) => option.value === getData?.amClassID
      )?.value || "",
    pmClassID:
      pmClassOptions.find(
        (option: { value: any }) => option.value === getData?.pmClassID
      )?.value || "",
    roleID:
      rolesOptions.find((option) => option.value === getData?.roleID)?.value ||
      "",
  };

  const onAccept = async (values: any) => {
    const payload = {
      id: userData.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      roleID: values.roleID,
      amClassID: values.amClassID,
      pmClassID: values.pmClassID,
    };
    await axiosInstance.post("/api/User/UpdateUser", payload);
    setIsPopupOpen(false);
    alert("successfully user Data update");
    if (onUpdate) {
      onUpdate();
    }
  };

  return (
    <ModalComponent
      isOpen={isPopupOpen}
      onClose={() => {
        setIsPopupOpen(false);
        setShowPopUp(false);
      }}
      acceptClassName=""
      popupClassName="bg-custom-gradient w-full max-w-xl mt-6"
    >
      <UseFormikForm
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        onSubmit={onAccept}
        formClassName=""
      >
        {(formik, handleSubmit) => (
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="relative">
                <InputFieldComponent
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  onBlur={formik.onBlur}
                />
                <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                  <FaUserPen />
                </div>
              </div>
              <div className="relative">
                <InputFieldComponent
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                />
                <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                  <FaPenToSquare />
                </div>
              </div>
              <div className="relative">
                <InputFieldComponent
                  label="Email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  onBlur={formik.onBlur}
                />
                <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                  <MdEmail />
                </div>
              </div>
              <div className="relative">
                <InputFieldComponent
                  label="Phone No"
                  type="text"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onKeyPress={(event: any) =>
                    !/^\d$/.test(event.key) && event.preventDefault()
                  }
                  maxLength={10}
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                />
                <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                  <FaPhoneFlip />
                </div>
              </div>
              <div>
                <Dropdown
                  label="Roles"
                  name="roleID"
                  options={rolesOptions}
                  defaultOption={rolesOptions.find(
                    (option) => option.label === userData.roleID
                  )}
                  onSelect={(option) =>
                    formik.setFieldValue("roleID", option?.value || "")
                  }
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                />
              </div>
              <div>
                <Dropdown
                  label="AM Class"
                  name="amClassID"
                  options={amClassOptions}
                  defaultOption={amClassOptions.find(
                    (option: any) => option.label === userData.amClassID
                  )}
                  onSelect={(option) =>
                    formik.setFieldValue("amClassID", option?.value || "")
                  }
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                />
              </div>
              <div>
                <Dropdown
                  label="PM Class"
                  name="pmClassID"
                  options={pmClassOptions}
                  defaultOption={pmClassOptions.find(
                    (option: any) => option.label === userData.pmClassID
                  )}
                  onSelect={(option) =>
                    formik.setFieldValue("pmClassID", option?.value || "")
                  }
                  labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                />
              </div>
            </div>
            <div className="w-1/2 my-2 lg:w-1/4 mx-auto">
              <button
                type="submit"
                className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </UseFormikForm>
    </ModalComponent>
  );
};

export default UserEdit;
