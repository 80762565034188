import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../AdminStructure/AdminLayout";
import axiosInstance from "../../../../Services/Interceptors/axiosInstance";
import { Link } from "react-router-dom";
import UseFormikForm from "../../../../Component-CSS/UseFormikForm/UseFormikForm";
import { FaQrcode } from "react-icons/fa";
import InputFieldComponent from "../../../../Component-CSS/Input/InputFieldComponent";
import * as Yup from "yup";

const AClassAttendance: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isSuccess && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSuccess]);

  const handleForm = async (values: any) => {
    const user = JSON.parse(sessionStorage.getItem("user") ?? '');
    const payload = {
      id: 0,
      timestamp: new Date().toISOString(),
      qrCodeNumber: parseInt(values.qrCodeNumber),
      userID: 0,
      logInUserID:user !== null ? user.id : 0
    };
    try {
      const results = await axiosInstance.post(
        "/api/Attendance/SaveAClassRoomAttendance",
        payload
      );
      if (results.data.id !== 0) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      }
    } catch (error) {
      console.error(`Error fetching from post method:`, error);
    }
  };
  return (
    <AdminLayout title="A-Class Attendance">
      <div className="relative mx-6 my-10">
        <button className="absolute py-1 px-3 -left-8 -top-2 -rotate-[10deg]  bg-teal-700 text-white font-bold">
          Attendance
        </button>
        <div className="shadow-teal p-8 border border-black">
          {!isSuccess ? (
            <UseFormikForm
              initialValues={{ qrCodeNumber: "" }}
              validationSchema={Yup.object().shape({
                qrCodeNumber: Yup.string().required("Enter QRCode Number"),
              })}
              onSubmit={handleForm}
              formClassName=""
            >
              {(formik, handleSubmit) => (
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className="relative">
                    <InputFieldComponent
                      ref={inputRef}
                      label="QRCode Number"
                      type="text"
                      name="qrCodeNumber"
                      onKeyPress={(event: any) =>
                        !/^\d$/.test(event.key) && event.preventDefault()
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        formik.setFieldValue("qrCodeNumber", value);
                        if (value.length === 7) {
                          formik.handleSubmit();
                        }
                      }}
                      labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                      onBlur={formik.onBlur}
                    />
                    <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                      <FaQrcode />
                    </div>
                    {formik.touched.qrCodeNumber &&
                    formik.errors.qrCodeNumber ? (
                      <div className="text-red-500 font-semibold">
                        {formik.errors.qrCodeNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-2 flex justify-center">
                    <button
                      type="submit"
                      className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </UseFormikForm>
          ) : (
            <>
              <span className="font-mono text-teal-700 font-bold">
                Attendance recorded successfully!
              </span>
              <br />
              <Link
                to=""
                onClick={() => setIsSuccess(false)}
                className="text-blue-500 font-bold underline mt-2"
              >
                Enter another code
              </Link>
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AClassAttendance;
