import React, { useEffect, useState } from "react";
import AdminLayout from "../../AdminStructure/AdminLayout";
import DataTable from "../../../../Component-CSS/DataTable/DataTable";
import axiosInstance from "../../../../Services/Interceptors/axiosInstance";
import { attendanceDataHeader } from "../../../../Constant/TableHeaders";
import Dropdown from "../../../../Component-CSS/Dropdown/Dropdown";
import { classOptions } from "../../../../Models/EnumHelper";

const AttendanceData: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);

  const fetchData = async (apiUrl: string) => {
    try {
      const results: any = await axiosInstance.get(apiUrl);
      const transformedData =
        Array.isArray(results.data) &&
        results.data.map((user: any) => {
          const { id, timeStamp, year, qrCodeNumber, logInUserName, ...rest } = user;
          return { 
            timeStamp:
             new Date(timeStamp).toLocaleString(),
            ...rest,
            logInUserName : logInUserName
          };
        });
      setUserData(transformedData);
    } catch (error) {
      console.error(`Error fetching data from API:`, error);
    }
  };

  const handleApiCall = async (selectedOption: { value: any }) => {
    if (!selectedOption) return;

    let apiUrl = "";
    switch (selectedOption.value) {
      case 1:
        apiUrl = "/api/Attendance/GetFrontDeskAttendanceUsers";
        break;
      case 2:
        apiUrl = "/api/Attendance/GetAClassRoomAttendanceUsers";
        break;
      case 3:
        apiUrl = "/api/Attendance/GetBClassRoomAttendanceUsers";
        break;
      default:
        console.log("No valid option selected.");
        return;
    }

    fetchData(apiUrl);
  };

  useEffect(() => {
    fetchData("/api/Attendance/GetFrontDeskAttendanceUsers");
  }, []);

  return (
    <AdminLayout title="Attendance User">
      <div className="mb-4">
        <Dropdown
          label="Attendance Class"
          name="roleID"
          options={classOptions}
          onSelect={handleApiCall}
          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
        />
      </div>
      <DataTable
        users={userData}
        recordsData={10}
        header={attendanceDataHeader}
      />
    </AdminLayout>
  );
};

export default AttendanceData;
