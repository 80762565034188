import React, { useEffect} from "react";
import MainLayouts from "../../Layout/MainLayout";
import axiosInstance from "../../Services/Interceptors/axiosInstance";
import { RoutesPath } from "../../Constant/RoutesConstant";
import { useNavigate } from "react-router-dom";

//import FormfacadeEmbed from "@formfacade/embed-react";
// import {StyleSheet, Text, View} from "react-native"
// import { WebView } from 'react-native-webview';


const Survey: React.FC = () => {
  useEffect(() => {
    fetchData();
  }, []); 
  
  const navigate = useNavigate();
  const fetchData = async () => {
    const response = await axiosInstance.get("/api/User/GetTreeMenuStatusByName", {
      params: { vstrMenuName: "Survey" },  // Query parameter
    });
    if(response.data !== true){
      navigate(RoutesPath.HOME) 
    }
  }
  

  return (
    

    // <WebView source={{ uri: "https://docs.google.com/forms/d/e/1FAIpQLSeWwtrdBMjBxtgWWwIEHlm6truEiUy1B0rM8KTHBTkv4dnvGA/viewform?embedded=true" }}
    // />
    <MainLayouts> 
      <div 
        className="iframe-container" 
        style={{ width: '100%', height: 'calc(605vh - 100px)', 
          //display: "flex",
          //flexDirection: "column",
          // alignItems: "center",
          justifyContent: "center", 
          //overflow:'hidden',
          paddingBottom: '100%',
          position: 'relative' }}>

        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeWwtrdBMjBxtgWWwIEHlm6truEiUy1B0rM8KTHBTkv4dnvGA/viewform?embedded=true"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '3750px',
            border: 'none',
          }}
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
          allowFullScreen
          title="Survey Form"
        >
          Loading…
        </iframe>
      </div>
    </MainLayouts>

  );
};

  

export default Survey;








      {/* <div className="my-10">
        <FormfacadeEmbed
          formFacadeURL="https://formfacade.com/include/106162782104820624295/form/1FAIpQLSeWwtrdBMjBxtgWWwIEHlm6truEiUy1B0rM8KTHBTkv4dnvGA/classic.js/?div=ff-compose"
          onSubmitForm={() => console.log("Form submitted")}
        />
      </div> */}

    