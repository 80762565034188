import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../AdminStructure/AdminLayout";
import UseFormikForm from "../../../Component-CSS/UseFormikForm/UseFormikForm";
import InputFieldComponent from "../../../Component-CSS/Input/InputFieldComponent";
import * as Yup from "yup";
import axiosInstance from "../../../Services/Interceptors/axiosInstance";
import Dropdown from "../../../Component-CSS/Dropdown/Dropdown";
import { rolesIdOptions } from "../../../Models/EnumHelper";

const AddUser: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isSuccess && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSuccess]);

  const handleForm = async (values: any) => {
    const payload = {
      username: values.username,
      password: values.password,
      roleID: values.roleID,
      id: values.id,
    };
    try {
      const results = await axiosInstance.post(
        "/api/User/AddRegisteredUsersLoginInfo",
        payload
      );
      if (results.status === 200) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);
      }
    } catch (error) {
      console.error(`Error submitting form:`, error);
    }
  };

  return (
    <AdminLayout title="Add User">
      <div className="w-full px-8 py-6 shadow-lg rounded-2xl bg-white overflow-auto max-h-[75vh]">
        {!isSuccess ? (
          <UseFormikForm
            initialValues={{
              username: "",
              password: "",
              roleID: "",
              id: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required("Enter Username"),
              password: Yup.string().required("Enter Password"),
              roleID: Yup.string().required("Select RoleID"),
              id: Yup.number().required("Enter ID"),
            })}
            onSubmit={handleForm}
            formClassName=""
          >
            {(formik, handleSubmit) => (
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <InputFieldComponent
                    label="Username"
                    type="text"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.username}
                    </div>
                  )}
                </div>
                <div>
                  <InputFieldComponent
                    label="Password"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div>
                  <Dropdown
                    label="role ID"
                    name="roleID"
                    options={rolesIdOptions}
                    onSelect={(option) =>
                      formik.setFieldValue("roleID", option?.value || "")
                    }
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  />
                  {formik.touched.roleID && formik.errors.roleID && (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.roleID}
                    </div>
                  )}
                </div>
                <div>
                  <InputFieldComponent
                    label="ID"
                    type="number"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  />
                  {formik.touched.id && formik.errors.id && (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.id}
                    </div>
                  )}
                </div>
                <div className="my-2 flex justify-center">
                  <button
                    type="submit"
                    className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </UseFormikForm>
        ) : (
          <div className="text-teal-700 font-bold text-center">
            User added successfully!
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AddUser;
