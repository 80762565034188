import React, { useState, useEffect } from 'react';
// import { slides } from "./data";
import logoImage from "../../Assets/MedExpo_logo.jpeg";

const animations = ['fade', 'slide-left', 'slide-right', 'zoom-in', 'zoom-out'];


const Slideshow: React.FC = () => {
  const [slides, setSlides] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    // Fetch data dynamically
    fetch('/data/Slideshow.json')
      .then((response) => response.json())
      .then((data) => setSlides(data))
      .catch((error) => console.error('Error fetching slides:', error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
      setAnimation(randomAnimation);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slides]);

  return slides.length > 0 ? (
    <div style={styles.slideshowContainer}>
      <img src={logoImage} alt={"Logo"} style={styles.expologo} />
      {slides.map(([name, logo, title], index) => (
        <div
          className={`slide ${index === currentIndex ? animation : ''}`}
          key={index}
          style={{
            ...styles.slide,
            opacity: index === currentIndex ? 1 : 0,
          }}
        >
          <div style={styles.sponsorText}>{title}</div>
          <img src={logo} alt={name} style={styles.image} />
          <div style={styles.bottomText}>{name}</div>
        </div>
      ))}
    </div>
   ) : (
    <div>Loading...</div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  slideshowContainer: {
    position: 'relative',
    width: '100%',
    height: '100vh', // Full-screen height
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column', // Added column layout for top text
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(120, 120, 120)',
  },
  sponsorText: {
    position: 'absolute',
    top: '10px',
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    fontSize: '4rem',
    fontWeight: 'bold',
    backgroundColor: 'rgba(90, 90, 90, 0.7)',
    padding: '0 250px',
    zIndex: 2,
  },
  slide: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 1s ease-in-out',
  },
  image: {
    maxWidth: '80%',
    minWidth: "40%",
    maxHeight: '80vh',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  bottomText: {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    fontSize: '2.5rem',
    backgroundColor: 'rgba(90, 90, 90, 0.6)',
    padding: '10px 0',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    zIndex: 2,
  },
  expologo: {
    position: 'absolute',
    top: '10px',
    left: '10px', // Ensures it's positioned to the left
    maxHeight: '100px',
    display: 'flex', // Helps align child elements
    justifyContent: 'flex-start', // Aligns content to the left
    alignItems: 'flex-start', // Aligns items to the top-left corner
    textAlign: 'left',
    // backgroundColor: 'rgba(90, 90, 90, 0.6)',
    padding: '10px',
    zIndex: 3,
  },
};

export default Slideshow;
