export const userDataHeader: { [key: string]: string } = {
  // id: "ID",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phoneNumber: "Phone Number",
  amClassID: "Am Class",
  pmClassID: "Pm Class",
  qrCodeNumber: "QR code",
  createdOn: "Date",
  roleID: "Role",
};

export const contactHeader: { [key: string]: string } = {
  name: "name",
  phoneNumber: "Phone Number",
  email: "Email",
  message: "message",
};

export const attendanceDataHeader: { [key: string]: string } = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phoneNumber: "Phone Number",
  logInUserName: "Scanned By",
};

export const displayNameMap: any = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phoneNumber: "Phone Number",
  roleID: "Role",
  amClassID: "AM Class",
  pmClassID: "PM Class",
  qrCodeBase64String: "QR Code",
  createdOn: "Create Date",
};
