import React, { useState, useEffect } from "react";
import { FaPlay, FaPause, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const SponsorsScroll = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("left");
  const [scrollSpeed, setScrollSpeed] = useState(30);

  const [SponsorsData, setsponsorsData] = useState<any[]>([]);
    
  useEffect(() => {
        fetch("/data/Sponsorlist.json")
          .then((response) => response.json())
          .then((data) => {
            // Filter sponsors by type from JSON
            const SponsorsData = data.filter((item) => item[4] === "Yes");
            
            
    
            // Update state
            setsponsorsData(SponsorsData);

          })
          .catch((error) => console.error("Error fetching sponsors:", error));
      }, []);


      
  const duplicatedSponsors = [...SponsorsData, ...SponsorsData];

  useEffect(() => {
    const scrollContainer = document.querySelector(".scroll-container");
    let animationFrameId;

    const scroll = () => {
      if (!isPaused && scrollContainer) {
        scrollContainer.scrollLeft += 1;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
          scrollContainer.scrollLeft = 0;
        }
      }
      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isPaused]);

  return (
    <div className="w-full bg-white py-12 overflow-hidden relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Our Sponsors</h2>
          <button
            onClick={() => setIsPaused(!isPaused)}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            aria-label={isPaused ? "Play scroll" : "Pause scroll"}
          >
            {isPaused ? (
              <FaPlay className="w-4 h-4 text-gray-600" />
            ) : (
              <FaPause className="w-4 h-4 text-gray-600" />
            )}
          </button>
        </div>

        <div
          className="scroll-container overflow-hidden relative"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div className="flex space-x-8 whitespace-nowrap">
            {duplicatedSponsors.map(([title, website, logo, description,scrollinclude,level], index) => (
              <div
                key={`${index}`}
                className="inline-block"
                role="group"
                aria-label={`Sponsor: ${title}`}
              >
                <div className="w-48 p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-transform duration-300 hover:scale-105">
                  <div className="relative pb-[100%] mb-4">
                    <img
                      src={logo}
                      alt={`${title} logo`}
                      className="absolute inset-0 w-full h-full object-contain"
                      loading="lazy"
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = "https://images.unsplash.com/photo-1599305445671-ac291c95aaa9";
                      }}
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-lg font-semibold text-gray-800 truncate">
                      {title}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1 truncate">
                      {level} Sponsor
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="absolute left-0 top-0 w-32 h-full bg-gradient-to-r from-white to-transparent z-10" />
        <div className="absolute right-0 top-0 w-32 h-full bg-gradient-to-l from-white to-transparent z-10" />
      </div>
    </div>
  );
};

export default SponsorsScroll;
 
