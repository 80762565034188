import React from "react";
import { useState } from "react";

const TabsComponent: React.FC<any> = ({ tabs, activeTab, setActiveTab }) => {
  // const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  return (
    <div>
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-8" aria-label="Tabs">
            {tabs.map((tab: any) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200
                  ${
                    activeTab === tab.id
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }
                `}
                  aria-current={activeTab === tab.id ? "page" : undefined}
                >
                  <Icon className="mr-2 h-5 w-5" />
                  {tab.label}
                </button>
              );
            })}
          </nav>
        </div>
      </div>

      <div>
        {tabs.find((tab: { id: string }) => tab.id === activeTab)?.component}
      </div>
    </div>
  );
};

export default TabsComponent;
