import React from "react";
import HeaderComponent from "../../../Component-CSS/Header/HeaderComponent";
import { RoutesPath } from "../../../Constant/RoutesConstant";
import logoImage from "../../../Assets/MedExpo_logo.jpeg";
import SidebarComponent from "../../../Component-CSS/Sidebar/SidebarComponent";
import { useNavigate } from "react-router-dom";

const AdminLayout: React.FC<{ children: React.ReactNode; title: string }> = ({
  children,
  title,
}) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen overflow-y-auto overflow-x-hidden flex flex-col bg-sky-50">
      <div className="flex-grow">
        <HeaderComponent
          navItems={[]}
          buttonLink={() => {
            sessionStorage.clear();
            navigate(RoutesPath.LOGIN);
          }}
          buttonName={"Back"}
          logoImage={logoImage}
        />
        <SidebarComponent>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">{title}</h2>
          {children}
        </SidebarComponent>
      </div>
    </div>
  );
};

export default AdminLayout;
