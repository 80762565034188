import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminStructure/AdminLayout";
import DataTable from "../../../Component-CSS/DataTable/DataTable";
import axiosInstance from "../../../Services/Interceptors/axiosInstance";
import {
  amClassOptions,
  pmClassOptions,
  rolesOptions,
} from "../../../Models/EnumHelper";
import { FaEdit, FaTrash } from "react-icons/fa";
import UserEdit from "./UserEdit";
import { userDataHeader } from "../../../Constant/TableHeaders";

const ManageUser: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  const fetchUserData = async () => {
    try {
      const results: any = await axiosInstance.get("/api/User/GetUsers");
      const transformedData =
        Array.isArray(results.data) &&
        results.data.map((user: any) => {
          const { createdOn, year,phoneNumber, qrCodeNumber, roleID, id, ...rest } = user;
          return {
          ...rest,
            qrCodeNumber,
            createdOn:
              new Date(createdOn).toLocaleString(),
            amClassID:
              amClassOptions.find((option) => option.value === user.amClassID)
                ?.label || user.amClassID,
            pmClassID:
              pmClassOptions.find((option) => option.value === user.pmClassID)
                ?.label || user.pmClassID,
            phoneNumber,
            roleID:
              rolesOptions.find((option) => option.value === user.roleID)
                ?.label || user.roleID,
            id,
          };
        });
      setUserData(transformedData);
    } catch (error) {
      console.error(`Error fetching data from API:`, error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // const handleDelete = async (item: any) => {
  //   const Payload = {
  //     id: item.id,
  //     firstName: item.firstName,
  //     lastName: item.lastName,
  //     email: item.email,
  //     phoneNumber: item.phoneNumber,
  //     roleID: item.roleID,
  //     amClassID: item.amClassID,
  //     pmClassID: item.pmClassID,
  //     year: 0,
  //     qrCodeNumber: 0,
  //   };
  //   try {
  //     console.log(Payload);
  //   } catch (error) {
  //     console.error(`Error fetching from post method:`, error);
  //   }
  // };

  return (
    <AdminLayout title="Registered User">
      <DataTable
        header={userDataHeader}
        users={userData}
        actions={(item) => {
          return (
            <div className="text-center">
              <button
                onClick={() => {
                  setSelectedUser(item);
                  setEditOpen(true);
                }}
                className="text-indigo-600 hover:text-indigo-900 mr-4"
              >
                <FaEdit className="inline-block" />
              </button>
              {/* <button
                onClick={() => handleDelete(item)}
                className="text-red-600 hover:text-red-900"
              >
                <FaTrash className="inline-block" />
              </button> */}
            </div>
          );
        }}
        recordsData={250}
        tableHeight={"calc(100vh - 20rem)"}
      />
      {editOpen && (
        <UserEdit
          openModal={editOpen}
          setShowPopUp={setEditOpen}
          userData={selectedUser}
          onUpdate={fetchUserData}
        />
      )}
    </AdminLayout>
  );
};

export default ManageUser;
