import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

interface FormProps {
  initialValues: { [key: string]: any };
  validationSchema: Yup.ObjectSchema<any>;
  onSubmit?: (values: any) => void;
  children: (
    formik: any,
    handleSubmit: () => void,
    isSubmitting: boolean
  ) => React.ReactNode;
  formClassName?: string;
}

const UseFormikForm: React.FC<FormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  formClassName,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,

    onSubmit: async (values, { setSubmitting }) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate async operation

      setIsSubmitting(true); // Set isSubmitting to true when submitting starts
      if (onSubmit) {
        await onSubmit(values);
      }
      setIsSubmitting(false); // Reset isSubmitting after submission completes
      setSubmitting(false); // Optional: useFormik handles this internally as well
    },
  });

  return (
    <div
      className={clsx(
        formClassName === undefined || null ? "bg-white" : formClassName
      )}
    >
      {children(formik, formik.handleSubmit, isSubmitting)}
      {/* Pass isSubmitting to children */}
    </div>
  );
};

export default UseFormikForm;
