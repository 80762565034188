import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

interface Option {
  value: string | number;
  label: string;
}

interface DropdownProps {
  options: Option[];
  defaultOption?: Option;
  onSelect: (option: Option) => void;
  label?: string;
  name?: string;
  labelClassName?: string;
  dropdownClassName?: string;
  optionClassName?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultOption,
  onSelect,
  label,
  name,
  labelClassName,
  dropdownClassName,
  optionClassName,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    defaultOption || null
  );
  const [openAbove, setOpenAbove] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const adjustDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - rect.bottom;
      const spaceAbove = rect.top;

      setOpenAbove(spaceBelow < 150 && spaceAbove > spaceBelow); // Adjust 150 as a threshold for space
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustDropdownPosition();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div {...rest} ref={dropdownRef}>
      {label && (
        <label
          htmlFor={name}
          className={clsx(
            labelClassName || "block text-sm font-bold text-tint-600"
          )}
        >
          {label}
        </label>
      )}
      <div className="relative inline-block w-full">
        <div
          id={name}
          className={clsx(
            "inline-flex justify-between items-center rounded-md border border-gray-300 shadow-sm px-4 py-2",
            dropdownClassName ||
              "bg-white text-sm font-medium text-gray-700 w-full"
          )}
          onClick={toggleDropdown}
        >
          {selectedOption ? selectedOption.label : "Select an option"}
          <span className="ml-2">{isOpen ? "▲" : "▼"}</span>
        </div>
        {isOpen && (
          <div
            className={clsx(
              "absolute bg-white border border-gray-300 z-10 rounded-md",
              openAbove ? "bottom-full mb-1" : "top-full mt-1",
              optionClassName || "w-full"
            )}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleOptionClick(option)}
                role="option"
                aria-selected={selectedOption?.value === option.value}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
