import { clsx } from "clsx";
import React from "react";

interface PopupProps {
  isOpen: boolean;
  children: React.ReactNode;
  acceptText?: string;
  declineClassName?: string;
  onDecline?: (values: any) => void;
  onAccept?: (values: any) => void;
  acceptClassName?: string;
  popupClassName?: string;
  onClose?: () => void;
  declineText?: string;
  showCloseButton?: boolean;
  childrenClassName?: string;
  showBackButton?: boolean;
}

const ModalComponent: React.FC<PopupProps> = ({
  isOpen,
  children,
  acceptText,
  onDecline,
  onAccept,
  onClose,
  declineClassName,
  declineText,
  acceptClassName,
  popupClassName,
  childrenClassName,
  showCloseButton = true,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-40">
      <div
        className={clsx(
          "relative p-4 w-full max-w-2xl max-h-full rounded-lg shadow bg-white",
          (popupClassName !== undefined || null) && popupClassName
        )}
      >
        {/* model header */}
        <div className="flex items-center justify-between p-2 rounded-t">
          {showCloseButton && (
            <button
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-3xl w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              &times;
            </button>
          )}
        </div>
        {/* Modal body */}
        <div
          className={clsx(
            "px-4 overflow-y-auto",
            childrenClassName === undefined || null
              ? "max-h-[400px]"
              : childrenClassName
          )}
        >
          {children}
        </div>
        {/* Modal footer */}
        <div className="flex items-center p-4 md:p-5 rounded-b justify-center">
          <button
            className={clsx(
              "focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center",
              acceptClassName === undefined || null
                ? "bg-blue-700 hover:bg-blue-800 text-white focus:ring-blue-300"
                : acceptClassName
            )}
            onClick={onAccept}
          >
            {acceptText}
          </button>
          <button
            className={clsx(
              "py-2.5 px-5 ms-3 text-sm font-medium focus:outline-none rounded-lg border border-gray-200 focus:ring-4",
              declineClassName === undefined || null
                ? "bg-white text-gray-900 hidden hover:text-blue-700 focus:ring-gray-100"
                : declineClassName
            )}
            onClick={onDecline}
          >
            {declineText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
