import React, { useState } from "react";
import clsx from "clsx";
import { FaTimes } from "react-icons/fa";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import { headerProps } from "../../Models/types";

const HeaderComponent: React.FC<headerProps> = ({
  navItems,
  logoImage,
  buttonLink,
  buttonName,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const handleToggle = () => setMenuOpen(!menuOpen);

  return (
    <header className="bg-[#26a096] px-6 py-4 top-0 w-full z-50 sticky flex justify-between items-center">
      <Link to="/">
        <img src={logoImage} alt="Logo" className="w-20 sm:w-40 ml-4 md:ml-0" />
      </Link>

      <div className="lg:flex hidden space-x-5 items-center">
        {navItems.map((item: { link: string; navItem: any }, index: any) => (
          <Link
            key={index}
            to={item.link}
            className={clsx(
              "font-medium p-2",
              location.pathname === item.link
                ? "text-red-700 underline decoration-red-700 decoration-1 underline-offset-8 font-bold text-lg"
                : "text-white"
            )}
          >
            {item.navItem}
          </Link>
        ))}
      </div>

      <div className="flex space-x-3 items-center">
        <button
          onClick={buttonLink}
          className="py-2 px-4 rounded-md bg-red-700 text-white font-medium"
        >
          {buttonName}
        </button>

        {/* Mobile menu button */}
        {navItems && navItems.length > 0 && (
          <button
            className="lg:hidden"
            aria-label="Toggle menu"
            onClick={handleToggle}
          >
            <HiMiniBars3BottomLeft className="w-7 h-7 fill-black" />
          </button>
        )}
      </div>

      {/* Mobile menu */}
      <div
        className={clsx(
          "fixed inset-y-0 right-0 z-40 bg-white p-6 transform transition-transform duration-300",
          menuOpen ? "translate-x-0 w-3/4" : "translate-x-full"
        )}
        aria-hidden={!menuOpen}
      >
        <button
          className="absolute top-6 right-6 p-2 rounded-full"
          aria-label="Close menu"
          onClick={handleToggle}
        >
          <FaTimes className="w-4 h-4 fill-black" />
        </button>

        <ul className="space-y-5 mt-10">
          {navItems.map((item: { link: string; navItem: any }, index: any) => (
            <li key={index}>
              <Link
                to={item.link}
                onClick={handleToggle}
                className={clsx(
                  "text-gray-900 font-medium text-lg",
                  location.pathname === item.link
                    ? "underline decoration-red-700 text-red-700 decoration-1 underline-offset-8 font-bold"
                    : ""
                )}
              >
                {item.navItem}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default HeaderComponent;
