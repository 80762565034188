import React, { useState } from "react";
import { RoutesPath } from "../../Constant/RoutesConstant";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  FaTimes,
  FaUserAlt,
  FaUserFriends,
  FaUsers,
  FaDesktop,
  FaClipboardCheck,
} from "react-icons/fa";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { MdPermContactCalendar, MdCoPresent } from "react-icons/md";
import { CiViewBoard } from "react-icons/ci";

const SidebarComponent: React.FC<any> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Logout Button
  const handleLogout = () => {
    // Clear user session (e.g., token from sessionStorage)
    sessionStorage.clear();
    // Navigate to login page
    navigate(RoutesPath.LOGIN);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const menuItems: any = [
    {
      title: "Registered Users",
      icon: <FaUserAlt className="w-5 h-5" />,
      linkPath: RoutesPath.MANAGE_USER,
    },
    {
      title: "AM Classes",
      icon: <FaUserFriends className="w-5 h-5" />,
      linkPath: RoutesPath.AM_CLASS,
    },
    {
      title: "PM Classes",
      icon: <FaUsers className="w-5 h-5" />,
      linkPath: RoutesPath.PM_CLASS,
    },
    {
      title: "Contact Us",
      icon: <MdPermContactCalendar className="w-5 h-5" />,
      linkPath: RoutesPath.CONTACT_DATA,
    },
    {
      title: "Front Desk",
      icon: <FaDesktop className="w-5 h-5" />,
      linkPath: RoutesPath.FRONT_DESK,
    },
    {
      title: "A-Class Attendance",
      icon: <CiViewBoard className="w-5 h-5" />,
      linkPath: RoutesPath.A_CLASS,
    },
    {
      title: "B-Class Attendance",
      icon: <FaClipboardCheck className="w-5 h-5" />,
      linkPath: RoutesPath.B_CLASS,
    },
    {
      title: "Attendance log",
      icon: <MdCoPresent className="w-5 h-5" />,
      linkPath: RoutesPath.ATTENDANCE_DATA,
    },
    {
      title: "Add login",
      icon: <MdCoPresent className="w-5 h-5" />,
      linkPath: RoutesPath.ADD_USER,
    },
  ];

  return (
    <div>
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-0 z-50 p-2 rounded-lg md:hidden"
        aria-label="Toggle Sidebar"
      >
        {isOpen ? (
          <FaTimes className="w-6 h-6" />
        ) : (
          <HiMiniBars3BottomLeft className="w-6 h-6" />
        )}
      </button>

      <aside
        className={`fixed top-0 left-0 h-full w-56 bg-gray-800 text-white transition-transform duration-300 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 overflow-y-auto`}
      >
        <div className="p-4">
          <nav>
            <ul className="space-y-2 mt-20">
              {menuItems.map((item: any, index: any) => {
                let isHidden = false;
                const user = sessionStorage.getItem("user");
                const parsedUser = user ? JSON.parse(user) : null;
                if (parsedUser === null) {
                  return handleLogout();
                }
                switch (item.linkPath) {
                  case RoutesPath.MANAGE_USER:
                    if (!parsedUser.isRegisteredUserVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.AM_CLASS:
                    if (!parsedUser.isAMClassVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.PM_CLASS:
                    if (!parsedUser.isPMClassVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.FRONT_DESK:
                    if (!parsedUser.isFrontDeskVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.A_CLASS:
                    if (!parsedUser.isAClassAttendanceVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.B_CLASS:
                    if (!parsedUser.isBClassAttendanceVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.ATTENDANCE_DATA:
                    if (!parsedUser.isAttendanceLogVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.CONTACT_DATA:
                    if (!parsedUser.isContactUsVisible) {
                      isHidden = true;
                    }
                    break;
                  case RoutesPath.ADD_USER:
                    if (!parsedUser.isAddLoginVisible) {
                      isHidden = true;
                    }
                    break;
                }

                if (!isHidden) {
                  return (
                    <li key={index}>
                      <Link
                        to={item.linkPath}
                        className={clsx(
                          "flex items-center space-x-4 py-2 px-2.5 rounded-lg",
                          location.pathname === item.linkPath
                            ? "border bg-white text-gray-800 text-bold"
                            : ""
                        )}
                      >
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </div>
      </aside>

      <main
        className={`transition-all duration-300 ${
          isOpen ? "ml-0 md:ml-56" : "ml-0 md:ml-56"
        } p-6`}
      >
        <div className="mx-auto">{children}</div>
      </main>
    </div>
  );
};

export default SidebarComponent;
