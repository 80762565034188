import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { RoutesPath } from "./Constant/RoutesConstant";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Registration from "./Pages/Registration/Registration";
import Sponsors from "./Pages/Sponsors/Sponsors";
import Gallery from "./Pages/Gallery/Gallery";
import ContactUs from "./Pages/ContactUs/ContactUs";
import ManageUser from "./Pages/Admin/ManageUser/ManageUser";
import VerifyMail from "./Pages/Registration/VerifyMail";
import Login from "./Pages/Login/Login";
import ContactUsData from "./Pages/Admin/ContactUs/ContactUsData";
import AmClass from "./Pages/Admin/Classes/AmClass/AmClass";
import PmClass from "./Pages/Admin/Classes/PmClass/PmClass";
import FrontDeskAttendance from "./Pages/Admin/Attendance/FrontDeskAttendance/FrontDeskAttendance";
import AClassAttendance from "./Pages/Admin/Attendance/AClassAttendance/AClassAttendance";
import BClassAttendance from "./Pages/Admin/Attendance/BClassAttendance/BClassAttendance";
import AttendanceData from "./Pages/Admin/Attendance/AttendanceData/AttendanceData";
import Survey from "./Pages/Survey/Survey";
import Adduser from "./Pages/Admin/Adduser/Adduser";
import Slideshow from "./Pages/Slideshow/Slideshow";

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path={RoutesPath.HOME} element={<Home />} />
        <Route path={RoutesPath.ABOUT_US} element={<AboutUs />} />
        <Route path={RoutesPath.REGISTRATION} element={<Registration />} />
        <Route path={RoutesPath.SPONSORS} element={<Sponsors />} />
        <Route path={RoutesPath.GALLERY} element={<Gallery />} />
        <Route path={RoutesPath.CONTACT} element={<ContactUs />} />
        <Route path={RoutesPath.MANAGE_USER} element={<ManageUser />} />
        <Route path={RoutesPath.VERIFY_EMAIL} element={<VerifyMail />} />
        <Route path={RoutesPath.LOGIN} element={<Login />} />
        <Route path={RoutesPath.CONTACT_DATA} element={<ContactUsData />} />
        <Route path={RoutesPath.AM_CLASS} element={<AmClass />} />
        <Route path={RoutesPath.PM_CLASS} element={<PmClass />} />
        <Route path={RoutesPath.FRONT_DESK} element={<FrontDeskAttendance />} />
        <Route path={RoutesPath.A_CLASS} element={<AClassAttendance />} />
        <Route path={RoutesPath.B_CLASS} element={<BClassAttendance />} />
        <Route path={RoutesPath.ATTENDANCE_DATA} element={<AttendanceData />} />
        <Route path={RoutesPath.SURVEY} element={<Survey />} />
        <Route path={RoutesPath.ADD_USER} element={<Adduser />} />
        <Route path={RoutesPath.SLIDESHOW} element={<Slideshow />} />
      </Routes>
    </>
  );
};

export default App;
