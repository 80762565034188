export const rolesOptions = [
  { value: 1, label: "Visitor" },
  { value: 2, label: "Medical Doctor" },
  { value: 3, label: "Nurse Practitioner" },
  { value: 4, label: "Physician Assistant" },
];

export const amClassOptions = [
  {
    value: 1,
    label: "Only Expo",
  },
  {
    value: 2,
    label: "Medical Error AM",
  },
  {
    value: 3,
    label: "Domestic Violence",
  },
];

export const pmClassOptions = [
  {
    value: 1,
    label: "Only Expo",
  },
  {
    value: 2,
    label: "Medical Error PM",
  },
  {
    value: 3,
    label: "Opioid Prescribing",
  },
];

export const classOptions = [
  {
    value: 1,
    label: "Front Desk",
  },
  {
    value: 2,
    label: "A-Class Attendance",
  },
  {
    value: 3,
    label: "B-Class Attendance",
  },
];

export const rolesIdOptions = [
  {
    value: 1,
    label: "Admin",
  },
  {
    value: 2,
    label: "AClass",
  },
  {
    value: 3,
    label: "BClass",
  },
  {
    value: 4,
    label: "FrontDesk",
  },
];
