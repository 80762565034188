import React, { forwardRef, InputHTMLAttributes } from "react";
import clsx from "clsx";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelClassName?: string;
  inputClassName?: string;
}

const InputFieldComponent = forwardRef<HTMLInputElement, InputProps>(
  ({ label, labelClassName, inputClassName, ...rest }, ref) => {
    return (
      <div>
        <label
          className={clsx(
            labelClassName === undefined || null
              ? "block text-sm font-bold mb-2 text-tint-600"
              : labelClassName
          )}
        >
          {label}
        </label>
        <input
          {...rest}
          ref={ref}
          className={clsx(
            inputClassName === undefined || null
              ? "border rounded w-full py-2 px-3 text-gray-500"
              : inputClassName
          )}
          autoComplete="off"
        />
      </div>
    );
  }
);

export default InputFieldComponent;
export type { InputProps };
