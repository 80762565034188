import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import InputFieldComponent from "../Input/InputFieldComponent";
import ExportTable from "./ExportTable";

interface DataTableProps {
  users: any;
  actions?: (items: any) => React.ReactNode;
  recordsData: number;
  header: { [key: string]: string };
  tableHeight?: string;
}

const DataTable: React.FC<DataTableProps> = ({
  users,
  actions,
  recordsData,
  header,
  tableHeight = "calc(100vh - 25rem)",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const columns = users.length > 0 ? Object.keys(users[0]) : [];
  const filteredUsers = users.filter((user: any) =>
    ["firstName", "lastName", "email"].some((key) =>
      user[key]?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const indexOfLastUser = currentPage * recordsData;
  const indexOfFirstUser = indexOfLastUser - recordsData;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / recordsData);

  const paginate = (pageNumber: React.SetStateAction<number>) =>
    setCurrentPage(pageNumber);

  const headers = columns.map((key) => header[key] || key);

  return (
    <div>
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 bg-gray-50 border-b border-gray-200 flex items-center justify-between flex-wrap">
          <h2 className="text-xl font-semibold text-gray-800">
            Total Users: {users.length}
          </h2>
          <div className="flex items-center gap-4">
            <div>
              <InputFieldComponent
                label=""
                type="text"
                placeholder="Search here ..."
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </div>
            <ExportTable
              data={filteredUsers}
              headers={columns.map((column) => ({
                headerName: column,
              }))}
              fileName="users_data.csv"
            />
          </div>
        </div>

        <div className="overflow-auto" style={{ height: `${tableHeight}` }}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {actions && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                )}
                {headers.map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex items-center">
                      <span>{header}</span>
                    </div>
                  </th>
                ))}
                
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentUsers.length > 0 ? (
                currentUsers.map((user: any) => (
                  <tr
                    key={user.id}
                    className="hover:bg-gray-50 transition-colors duration-200"
                  >

                  {/* action button moved */}
                    {actions && (
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        {actions(user)}
                      </td>
                    )}

                    {columns.map((column) => (
                      <td
                        key={column}
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-wrap"
                      >
                        {column === "status" ? (
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              user[column] === "Active"
                                ? "bg-green-100 text-green-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {user[column]}
                          </span>
                        ) : (
                          user[column]
                        )}
                      </td>
                    ))}
                    
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={columns.length + (actions ? 1 : 0)}
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 z-0">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                currentPage === 1
                  ? "bg-gray-100 text-gray-400"
                  : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-100 text-gray-400"
                  : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing&nbsp;
                <span className="font-medium">{indexOfFirstUser + 1}</span>
                &nbsp; to&nbsp;
                <span className="font-medium">
                  {Math.min(indexOfLastUser, users.length)}&nbsp;
                </span>
                of&nbsp;<span className="font-medium">{users.length}</span>
                &nbsp; results
              </p>
            </div>
            <div>
              <nav className="inline-flex rounded-md shadow-sm -space-x-px">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium ${
                    currentPage === 1
                      ? "bg-gray-100 text-gray-400"
                      : "bg-white text-gray-700 hover:bg-gray-50"
                  }`}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={` inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                      currentPage === index + 1
                        ? "bg-blue-50 text-blue-600 border-blue-500"
                        : "bg-white text-gray-700 hover:bg-gray-50"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium ${
                    currentPage === totalPages
                      ? "bg-gray-100 text-gray-400"
                      : "bg-white text-gray-700 hover:bg-gray-50"
                  }`}
                >
                  Next
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
