import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import MainLayouts from "../../Layout/MainLayout";
import { FaUserPen, FaLock } from "react-icons/fa6";
import UseFormikForm from "../../Component-CSS/UseFormikForm/UseFormikForm";
import InputFieldComponent from "../../Component-CSS/Input/InputFieldComponent";
import axiosInstance from "../../Services/Interceptors/axiosInstance";
import { useNavigate } from "react-router-dom";
import { RoutesPath } from "../../Constant/RoutesConstant";

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate(RoutesPath.MANAGE_USER);
    }
  }, []);

  const handleForm = async (values: any) => {
    setIsLoading(true);
    try {
      const results = await axiosInstance.post(
        "/api/User/ValidateUser",
        values
      );
      if (results.data.id > 0) {
        sessionStorage.setItem("token", results.data.token);
        sessionStorage.setItem("user", JSON.stringify(results.data));
        navigate(RoutesPath.ATTENDANCE_DATA);
      } else {
        setMessage("Invalid Credentials");
      }
    } catch (error) {
      console.error(`Error fetching from post method:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayouts>
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-teal-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full mx-auto space-y-8 bg-white p-8 rounded-xl shadow-lg">
          <div className="text-center">
            <div className="relative w-24 h-24 mx-auto mb-4">
              <img
                src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-4.0.3"
                alt="Medical Expo Logo"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h2 className="mt-6 text-3xl font-bold text-gray-900">
              Medical Expo 2024
            </h2>
          </div>

          <UseFormikForm
            initialValues={{ userName: "", password: "" }}
            validationSchema={Yup.object().shape({
              userName: Yup.string().required("Enter Username"),
              password: Yup.string().required("Enter Password"),
            })}
            onSubmit={handleForm}
            formClassName=""
          >
            {(formik, handleSubmit) => (
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div className="relative">
                  <InputFieldComponent
                    label="User Name"
                    type="text"
                    name="userName"
                    onChange={(event) => {
                      formik.setFieldValue("userName", event.target.value);
                    }}
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                    onBlur={formik.onBlur}
                  />
                  <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                    <FaUserPen />
                  </div>
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.userName}
                    </div>
                  ) : null}
                </div>
                <div className="relative">
                  <InputFieldComponent
                    label="Password"
                    type="password"
                    name="password"
                    onChange={(event) => {
                      formik.setFieldValue("password", event.target.value);
                    }}
                    labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                  />
                  <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                    <FaLock />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500 font-semibold">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
                <div className="w-1/2 my-2 lg:w-1/4 mx-auto">
                  <button
                    type="submit"
                    className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
                {message && (
                  <div className="mt-6 p-4 bg-red-50 rounded-md">
                    <div className="flex items-center">
                      <p className="text-red-800">{message}</p>
                    </div>
                  </div>
                )}
              </form>
            )}
          </UseFormikForm>
        </div>
      </div>
    </MainLayouts>
  );
};

export default Login;
