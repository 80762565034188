import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminStructure/AdminLayout";
import DataTable from "../../../Component-CSS/DataTable/DataTable";
import axiosInstance from "../../../Services/Interceptors/axiosInstance";
import { contactHeader } from "../../../Constant/TableHeaders";

const ContactUsData: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);
  const fetchUserData = async () => {
    try {
      const results: any = await axiosInstance.get(
        "/api/Contact/GetConnectedUserList"
      );
      const transformedData =
        Array.isArray(results.data) &&
        results.data.map((user: any) => {
          const { id, ...rest } = user;
          return {
            ...rest,
          };
        });
      setUserData(transformedData);
    } catch (error) {
      console.error(`Error fetching data from API:`, error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <AdminLayout title="Contact Us">
      <DataTable
        users={userData}
        recordsData={10}
        header={contactHeader}
        tableHeight={"calc(100vh - 20rem)"}
      />
    </AdminLayout>
  );
};

export default ContactUsData;
