import React, {useState, useEffect}  from "react";
import MainLayouts from "../../Layout/MainLayout";
import Banner from "../../Assets/BannerImg.png";
import { Link } from "react-router-dom";
import {
  FaHandHoldingHeart,
  FaCircleCheck,
  FaArrowRightLong,
} from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import { charitiesDataType, ListItem } from "../../Models/types";
import { RoutesPath } from "../../Constant/RoutesConstant";
import Contact from "../../Component/Contact/Contact";
import AddressMap from "../../Component/AddressMap/AddressMap";
import SponsorsScroll from "../../Component-CSS/Sponsorsscroll/Sponsorsscroll";
import axiosInstance from "../../Services/Interceptors/axiosInstance";

const Home: React.FC = () => {
   const [buttonName, setButtonName] = useState('Register');
      useEffect(() => {
        fetchData();
      }, []); 

      //The async function inside useEffect
const fetchData = async () => {
  const response = await axiosInstance.get("/api/User/GetTreeMenuStatusByName", {
    params: { vstrMenuName: "Registration" },  // Query parameter
  });
  if(response.data === true){
    setButtonName('Register');
  }else{
    setButtonName('Survey');
  }
}

  const aboutData: ListItem[] = [
    {
      text: "Mandatory CME Class Offered",
      subItems: [
        { text: "Domestic Violence" },
        { text: "Medical Errors" },
        { text: "Controlled Substance Prescribing" },
      ],
    },
    {
      text: "Food, Entertainment, Booths",
    },
    {
      text: "Prizes",
    },
    { text: "For Physicians, PA’s, NP’s Only" },
    { text: "FREE REGISTRATION" },
  ];

  const charitiesData: charitiesDataType[] = [
    {
      imgPath:
        "https://static.wixstatic.com/media/0fc399_b0f2b3e48ea746c698201984681cbed7~mv2.png/v1/fill/w_363,h_153,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/IES.png",
      subTitle: "",
      title: "",
    },
    {
      imgPath:
        "https://mcchildrensalliance.org/wp-content/uploads/2022/10/cropped-cropped-cropped-LOGO-ChildrensAlliance-FullColor-Transparent-1.png",
      subTitle: "",
      title: "",
    },
    {
      imgPath:
        "https://www.arcmarion.org/wp-content/uploads/the-arc-marion-top-logo.png",
      subTitle: "",
      title: "",
    },
    {
      imgPath:
        "https://images.squarespace-cdn.com/content/v1/56f0545e746fb991aa14ee95/1508943558451-DSHWK1GSV76CN9S1Z996/Main+Seal.png?format=1500w",
      subTitle: "",
      title: "",
    },
  ];

  return (
    <MainLayouts>
      {/* Banner */}
      <div
        className="relative bg-cover object-cover bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          height: "calc(100vh-31px)",
        }}
      >
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl text-center">
            <h2 className="mb-6 font-sans md:text-4xl font-bold tracking-tight text-black text-2xl sm:leading-none">
              <span className="text-red-600">
                22<sup>nd</sup>&nbsp;
              </span>
              <br className="block md:hidden" />
              <span className="uppercase underline">annual medical expo</span>
            </h2>
            <p className="md:text-2xl mb-6 text-red-600 font-bold">
              SATURDAY, JANUARY 25, 2025 <br /> 8:00 AM - 4:00 PM
            </p>
          
           {buttonName === 'Register' && (
            <>
            <p className="text-black uppercase max-w-md mb-6 font-semibold sm:text-lg md:text-xl sm:mx-auto">
            for healthcare professionals only physicians, physician assistants
            & nurse practitioners
            </p>
            </>
           )}

          {buttonName === 'Survey' && (
            <>
            <p className="text-black uppercase max-w-md mb-6 font-semibold sm:text-lg md:text-xl sm:mx-auto">
            Thank You for Joining Us!
            </p>
            <p className="text-black uppercase max-w-md mb-6 font-semibold sm:text-lg md:text-xl sm:mx-auto">
            The Annual Medical Expo has come to a close. We are deeply grateful for your participation and support.
            </p>
            <p className="text-black uppercase max-w-md mb-6 font-semibold sm:text-lg md:text-xl sm:mx-auto">See you in 2026!</p>
            <p className="text-black uppercase max-w-md mb-6 font-semibold sm:text-lg md:text-xl sm:mx-auto">Please give as feedback!</p>
            </>
           )}

          </div>
          <div className="flex gap-4 items-center justify-center">
            <Link
              to={buttonName === 'Register' ? RoutesPath.REGISTRATION : RoutesPath.SURVEY}
              className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
            >
            {buttonName === 'Register' ? 'Register' : 'Survey'}
            </Link>
            <Link
              to={RoutesPath.VERIFY_EMAIL}
              className="capitalize px-6 py-2 min-w-[120px] text-center text-white bg-teal-700 border border-teal-700 rounded hover:bg-transparent hover:text-teal-900"
            >
              verify register
            </Link>
          </div>
        </div>
      </div>

      {/* CME Class Information */}
      <div className="bg-white">
        <section className="max-w-7xl mx-auto px-6 mb-10 sm:px-6 lg:px-8">
          <h2 className="text-teal-900 text-4xl font-bold font-manrope leading-normal text-center mb-10">
            CME Class Available
          </h2>
          <p className="text-center text-gray-800 mb-8 text-lg">
            Enhance your knowledge and fulfill Mandatory CME Hours requirements
            with our expert-led classes.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
              <h3 className="text-teal-900 text-xl font-semibold mb-4 flex items-center gap-2">
                <FaCircleCheck className="text-teal-700" />
                Domestic Violence
              </h3>
              <p className="text-gray-600">Time: 9:00 AM - 11:00 AM</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
              <h3 className="text-teal-900 text-xl font-semibold mb-4 flex items-center gap-2">
                <FaCircleCheck className="text-teal-700" />
                Prevention of Medical Errors
              </h3>
              <p className="text-gray-600">
                Time: 8:30 AM - 10:30 AM or 1:30 PM - 3:30 PM
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
              <h3 className="text-teal-900 text-xl font-semibold mb-4 flex items-center gap-2">
                <FaCircleCheck className="text-teal-700" />
                Opioid Prescribing
              </h3>
              <p className="text-gray-600">Time: 1:30 PM - 3:30 PM</p>
            </div>
          </div>
        </section>
      </div>

      {/* About us */}
      <div className="bg-teal-100 py-6">
        <section className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
            <div className="relative hidden md:block">
              <img
                src="https://annualmedicalexpo.com/images/image13.jpg"
                alt=""
                className="rounded-lg shadow-lg mb-10 w-11/12"
              />
              <img
                src="https://annualmedicalexpo.com/images/image1.jpg"
                alt=""
                className="absolute bottom-0 right-0 w-2/3 rounded-lg shadow-lg border-4 border-white"
              />
            </div>

            <div className="space-y-6">
              <h2 className="text-xl font-extrabold text-teal-900 flex items-center gap-x-2">
                <FaHandHoldingHeart />
                About Us
              </h2>
              <p className="text-gray-500">
                North Central Florida’s largest Medical Expo for Healthcare
                professionals
              </p>

              <ul className="space-y-3 text-teal-700 font-medium">
                {aboutData.map((item, index) => (
                  <li key={index} className="flex flex-col">
                    <div className="flex items-center">
                      <FaCircleCheck className="h-5 w-5 mr-2" />
                      {item.text}
                    </div>
                    {item.subItems && item.subItems.length > 0 && (
                      <ul className="ml-6 space-y-2">
                        {item.subItems.map((subItem, index) => (
                          <li key={index} className="flex items-center">
                            <GoDash className="h-5 w-5 mr-2" />
                            {subItem.text}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>

              <Link
                to={RoutesPath.ABOUT_US}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  border-red-700 bg-red-700 hover:text-red-700 hover:bg-transparent"
              >
                View More
                <FaArrowRightLong className="ml-2" />
              </Link>
            </div>
          </div>
        </section>
      </div>

      {/* Charities Work */}
      <div className="bg-white">
        <section className="max-w-7xl mx-auto px-6 mb-10 sm:px-6 lg:px-8">
          <h2 className="text-teal-900 text-2xl font-bold font-manrope leading-normal text-center mb-10">
            Charities We Support
          </h2>
          <p className="text-center text-gray-700 mb-6">
            The Annual Medical Expo is committed to giving back to the
            community. Proceeds from the event help support a variety of
            impactful local and national charities. These organizations work
            tirelessly to improve lives through essential services, community
            programs, and support for those in need. By attending or sponsoring
            the expo, you’re not only participating in a premier medical event
            but also contributing to meaningful causes that make a difference.
            Together, we’re building a stronger, healthier community. Thank you
            for your support!
          </p>
          <div className="flex flex-wrap justify-center">
            {charitiesData.map((item: charitiesDataType, index: React.Key) => (
              <div
                key={index}
                className="relative mx-auto bg-white bg-opacity-20 bg-cover bg-center"
              >
                <img
                  className="max-h-64 max-w-96 object-cover rounded-lg "
                  src={item.imgPath}
                  alt="Charity Image"
                />
                {/* <div className="text-black w-1/2">
                  <div className="bg-transparent bg-opacity-95 p-5 opacity-90 backdrop-blur-lg rounded-l-lg">
                     <p className="mb-4 font-serif font-light">
                      {item.subTitle}
                    </p>
                    <h2 className="text-lg font-semibold">{item.title}</h2> 
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>

      <SponsorsScroll />

      {/* Contact Us */}
      <section className="relative my-10">
        <h2 className="text-teal-900 text-2xl font-bold font-manrope leading-normal text-center mb-10">
          Contact Us
        </h2>
        <div className="container px-5 mx-auto flex flex-col sm:flex-row">
          <div className="sm:w-full md:w-1/2 lg:w-1/3 bg-white rounded-lg p-8 flex flex-col w-full relative z-10 order-2 sm:order-1">
            <h2 className="text-gray-900 text-lg mb-4 font-medium title-font">
              Contact Us
            </h2>
            <Contact />
          </div>

          <div className="sm:w-full md:w-1/2 lg:w-2/3 relative order-2 mt-4 sm:mt-0">
            <div className="sm:relative md:absolute inset-0">
              <AddressMap />
            </div>
          </div>
        </div>
      </section>
    </MainLayouts>
  );
};

export default Home;
