import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayout";
import Banner from "../../Assets/BannerImg.png";
import { Link } from "react-router-dom";
import { TbWorld } from "react-icons/tb";
import { IoFastFoodSharp } from "react-icons/io5";




const Sponsors: React.FC = () => {

  
    // Separate states for each sponsor list
    const [GrandSponsorsList, setGrandSponsors] = useState<any[]>([]);
    const [FoodSponsorList, setFoodSponsors] = useState<any[]>([]);
    const [GoldList, setGoldSponsors] = useState<any[]>([]);
    const [PlatinumList, setPlatinumSponsors] = useState<any[]>([]);

    useEffect(() => {
      fetch("/data/SponsorList.json")
        .then((response) => response.json())
        .then((data) => {
          // Filter sponsors by type from JSON
          const GrandSponsorsList = data.filter((item) => item[5] === "Grand");
          const PlatinumList = data.filter((item) => item[5] === "Platinum");
          const GoldList = data.filter((item) => item[5] === "Gold");
          const FoodSponsorList = data.filter((item) => item[5] === "Food");
  
          // Update state
          setGrandSponsors(GrandSponsorsList);
          setPlatinumSponsors(PlatinumList);
          setGoldSponsors(GoldList);
          setFoodSponsors(FoodSponsorList);
        })
        .catch((error) => console.error("Error fetching sponsors:", error));
    }, []);


    // Fetching grand sponsors
    // useEffect(() => {
    //   fetch("/data/Grandsponsor.json")
    //     .then((response) => response.json())
    //     .then((data) => setGrandSponsors(data))
    //     .catch((error) => console.error("Error fetching grand sponsors:", error));
    // }, []);
  
    // Fetching food sponsors
    // useEffect(() => {
    //   fetch("/data/Foodsponsor.json")
    //     .then((response) => response.json())
    //     .then((data) => setFoodSponsors(data))
    //     .catch((error) => console.error("Error fetching food sponsors:", error));
    // }, []);
  
    // // Fetching gold sponsors
    // useEffect(() => {
    //   fetch("/data/GoldList.json")
    //     .then((response) => response.json())
    //     .then((data) => setGoldSponsors(data))
    //     .catch((error) => console.error("Error fetching gold sponsors:", error));
    // }, []);
  
    // // Fetching platinum sponsors
    // useEffect(() => {
    //   fetch("/data/PlatinumList.json")
    //     .then((response) => response.json())
    //     .then((data) => setPlatinumSponsors(data))
    //     .catch((error) => console.error("Error fetching platinum sponsors:", error));
    // }, []);


  return (
    <MainLayouts>
      {/* event sponsors */}
      <div
        className="relative bg-cover object-cover bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          height: "calc(100vh-31px)",
        }}
      >
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h1 className="text-xl font-extrabold uppercase leading-tight tracking-tight text-red-700">
              event sponsors
            </h1>
            <p className="my-5 text-base text-black sm:text-lg">
              Welcome to the 22<sup>nd</sup>&nbsp; Annual Medical Expo of North
              Central Florida. <br />
              Sincere thanks to all our sponsors for supporting this event
            </p>
          </div>
        </div>
      </div>

      {/* grand sponsors */}
      <div className="relative bg-white">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-sky-50"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-10 rounded-3xl bg-white text-black flex items-center justify-center overflow-hidden">
          <div className="w-full max-w-6xl px-4 sm:px-6 mb-10">
            {GrandSponsorsList.map(([title, website, img, description], index) => (
              <div
                className="grid grid-cols-1 gap-8 md:grid-cols-2"
                key={index}
              >
                {/* Company Image */}
                <div className="relative flex items-center sm:m-10">
                  <div className="absolute -top-10 md:top-20 lg:-top-20 -right-10 sm:w-32 h-56 lg:w-56 bg-sky-200 -rotate-12 rounded-lg border-sky-500 border-b-4 border-r-8 max-sm:hidden" />
                  <div className="relative z-10 bg-white -right-1/2 -translate-x-1/2 grow text-center shadow-xl -rotate-2 rounded-xl text-nowrap border-slate-950 border-b-4 border-r-8">
                    <img
                      src={img}
                      alt="Company Logo"
                      className="w-36 h-36 object-contain mx-auto"
                    />
                  </div>
                  <div className="absolute -bottom-10 md:bottom-20 lg:-bottom-20 -left-32 z-90 sm:w-32 sm:h-32 lg:w-64 lg:h-64 bg-sky-200 rotate-12 rounded-lg border-sky-500 border-r-4 border-b-8 max-sm:hidden" />
                </div>

                {/* Details */}
                <div className="flex flex-col justify-center text-center md:text-left z-10">
                  <h1 className="text-xl font-extrabold uppercase leading-tight tracking-tight text-red-700">
                    grand sponsors
                  </h1>
                  <p className="mt-4 text-base sm:text-lg md:text-xl font-medium text-balance">
                    {title}
                  </p>
                  <p className="mt-4 text-base text-balance">
                    {description}
                  </p>
                  <div className="mt-6 sm:mt-8 flex flex-wrap gap-4 w-1/2 mx-auto md:mx-0">
                    <Link
                      to={website}
                      className="rounded-lg p-3 grow text-center bg-red-700 text-white font-bold uppercase text-sm tracking-widest transition"
                    >
                      Visit
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Platinum */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-6">
          <div className="relative max-w-5xl sm:mx-auto sm:text-center">
            <h1 className="text-xl font-extrabold uppercase leading-tight tracking-tight text-red-700 mb-4">
              Platinum Sponsor
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 auto-cols-fr mb-10">
              {PlatinumList.map(([name, url, logo], index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center p-4 border-2 border-gray-200 hover:border-rose-600 rounded-2xl group min-h-[120px] transition-all duration-300"
                >
                  <div className="mb-2 w-32 aspect-square flex items-center justify-center">
                    <img
                      className="max-w-full max-h-full object-contain mx-2"
                      src={logo}
                      alt={`${name} logo`}
                    />
                  </div>
                  <h5 className="text-center text-lg font-medium text-gray-900 group-hover:text-rose-600">
                    {name}
                  </h5>
                  <Link
                    to={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 font-sans font-medium text-base no-underline flex items-center"
                  >
                    <TbWorld className="mr-1" /> Visit Site
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* gold */}
      <div className="relative bg-white">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-sky-50"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-4xl sm:mx-auto sm:text-center">
            <h1 className="text-xl font-extrabold uppercase leading-tight tracking-tight text-red-700 mb-4">
              gold sponsors
            </h1>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-10">
              {GoldList.map(([name, url, img], index) => (
                <div key={index} className="w-full max-w-xs text-center">
                  <div
                    className={`object-cover object-center w-full h-32 mx-auto rounded-lg bg-blue-100 border-4 border-blue-200`}
                  >
                    <div className="p-6">
                      <h5 className="text-base font-semibold">{name}</h5>
                      <div className="flex justify-center">
                        <Link
                          to={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 font-sans font-medium text-base no-underline flex items-center mt-2"
                        >
                          <TbWorld className="mr-1" /> Visit Site
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Food Sponsor */}
      <div className="relative">
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-5xl sm:mx-auto sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase mb-6">
              Food Sponsor
            </h2>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-3 items-center">
              {FoodSponsorList.map(([title, url, logo, bgColor], index) => (
                <div
                  key={index}
                  className={`w-full h-32 p-6 rounded-lg shadow-md ${bgColor}`}
                >
                  <div className="flex items-center">
                    <span><IoFastFoodSharp className="text-2xl" /></span>
                    <p className="text-lg font-semibold ms-2">{title}</p>
                  </div>
                  <Link
                    to={typeof url === "string" ? url : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-auto w-8 h-8 flex items-center justify-center bg-white rounded-full shadow hover:shadow-lg transition"
                  >
                    <span className="text-lg font-bold text-gray-600">
                      &rarr;
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayouts>
  );
};

export default Sponsors;
