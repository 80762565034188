import React from "react";
import { IoMailOpenOutline, IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import logoImage from "../../Assets/MedExpo_logo.jpeg";
import { RoutesPath } from "../../Constant/RoutesConstant";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const FooterComponent: React.FC = () => {
  const contactItems = [
    {
      type: "email",
      icon: <IoMailOpenOutline className="w-5 h-5 text-black shrink-0" />,
      label: "annualmedicalexpo@gmail.com",
      href: "mailto:annualmedicalexpo@gmail.com",
    },
    {
      type: "address",
      icon: <IoLocationOutline className="w-5 h-5 text-black shrink-0" />,
      label: "2030 NE 36TH Ave, Ocala, FL 34470",
    },
  ];

  // Social media data
  const socialLinks = [
    {
      name: "Facebook",
      icon: <FaFacebook className="w-6 h-6" />,
      url: "",
    },
    {
      name: "Instagram",
      icon: <FaInstagram className="w-6 h-6" />,
      url: "",
    },
    {
      name: "Twitter",
      icon: <FaTwitter className="w-6 h-6" />,
      url: "",
    },
  ];

  return (
    <footer className="bg-gradient-to-r from-teal-700 to-transparent text-black">
      <svg
        className="footer-wave-svg w-full h-[30px] -top-px relative block bg-transparent"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 100"
        preserveAspectRatio="none"
      >
        <path
          className="footer-wave-path fill-sky-50"
          d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
        />
      </svg>
      <div className="max-w-screen-xl px-4 pt-10 pb-6 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
          {/* logo */}
          <div>
            <div className="flex justify-center text-black sm:justify-start">
              <Link to="/">
                <img src={logoImage} alt="Logo" className="w-40" />
              </Link>
            </div>

            <p className="max-w-md mx-auto mt-6 leading-relaxed text-center text-black sm:max-w-xs sm:mx-0 sm:text-left">
              Raising The Standards in Health Care
            </p>

            <ul className="flex justify-center gap-6 mt-6 md:gap-8 sm:justify-start">
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.url}
                    rel="noopener noreferrer"
                    // target="_blank"
                    className="text-black transition hover:text-white"
                  >
                    <span className="sr-only">{link.name}</span>
                    {link.icon}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* Photos and Contact Us */}
          <div className="grid grid-cols-1 gap-8 lg:col-span-3 md:grid-cols-3">
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-black">Upcoming Expo</p>
              <p className="max-w-md mx-auto mt-6 leading-relaxed text-center text-black sm:max-w-xs sm:mx-0 sm:text-left">
                22nd Annual Medical Expo
                <br />
                January 25, 2025
                <br />
                8 am - 4 pm
                <br />
                2030 NE 36th Ave, Ocala, FL 34470
              </p>
            </div>
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-black">Contact Us</p>
              <ul className="mt-8 space-y-4 text-sm">
                {contactItems.map((item, index) => (
                  <li key={index}>
                    {item.type !== "address" ? (
                      <a
                        className="flex items-center justify-center sm:justify-start gap-1.5 group"
                        href={item.href}
                      >
                        {item.icon}
                        <span className="text-black transition group-hover:text-white/75">
                          {item.label}
                        </span>
                      </a>
                    ) : (
                      <div className="flex items-start justify-center sm:justify-start gap-1.5">
                        {item.icon}
                        <address className="-mt-0.5 not-italic text-black">
                          {item.label}
                        </address>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="m-auto">
              <Link
                to={RoutesPath.LOGIN}
                className="py-2 px-4 rounded-md bg-red-700 text-white font-medium"
              >
                Login
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-6 mt-12 border-t border-gray-800">
          <div className="text-center sm:flex sm:justify-between sm:text-left">
            <p className="text-sm text-black">
              <span className="block sm:inline">All rights reserved.</span>
              &nbsp;&nbsp;
              <Link
                className="inline-block text-black underline transition hover:text-white"
                to="/"
              >
                Terms & Conditions
              </Link>
              &middot;
              <Link
                className="inline-block text-black underline transition hover:text-white"
                to="/"
              >
                Privacy Policy
              </Link>
            </p>

            <p className="mt-4 text-sm text-black sm:order-first sm:mt-0">
              &copy; 2024 The India Association Cultural and Educational Center
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
