import React from "react";
import { CSVLink } from "react-csv";
import { FaFileExport } from "react-icons/fa6";

interface ExportProps {
  data: any[];
  headers: any;
  fileName?: string;
}

const ExportTable: React.FC<ExportProps> = ({ data, headers, fileName }) => {
  return (
    <div>
      <CSVLink
        data={data}
        headers={headers.map((header: any) => header.headerName)}
        filename={fileName}
        className="flex items-center border rounded py-1 px-3 bg-white text-gray-500 mt-1 text-lg"
      >
        <FaFileExport className="mr-2" />
        Export CSV
      </CSVLink>
    </div>
  );
};

export default ExportTable;
