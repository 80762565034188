import React, { useState } from "react";
import * as Yup from "yup";
import MainLayouts from "../../Layout/MainLayout";
import UseFormikForm from "../../Component-CSS/UseFormikForm/UseFormikForm";
import InputFieldComponent from "../../Component-CSS/Input/InputFieldComponent";
import { MdEmail } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import axiosInstance from "../../Services/Interceptors/axiosInstance";
import {
  amClassOptions,
  pmClassOptions,
  rolesOptions,
} from "../../Models/EnumHelper";
import { FaXmark } from "react-icons/fa6";
import { displayNameMap } from "../../Constant/TableHeaders";

const VerifyMail: React.FC = () => {
  const [response, setResponse] = useState<string | any>("");
  const [data, setData] = useState<any>(null);

  const handleEmailResponse = async (values: { email: string }) => {
    const payload = {
      id: 0,
      firstName: "",
      lastName: "",
      email: values.email,
      phoneNumber: "",
      roleID: 0,
      amClassID: 0,
      pmClassID: 0,
      year: 0,
      qrCodeNumber: 0,
    };

    try {
      const results = await axiosInstance.post("/api/User/VerifyUser", payload);
      const resultData = results.data;

      if (resultData) {
        const transformedData = Array.isArray(resultData)
          ? resultData.map((user: any) => ({
              ...user,
              roleID:
                rolesOptions.find((option) => option.value === user.roleID)
                  ?.label || user.roleID,
              amClassID:
                amClassOptions.find((option) => option.value === user.amClassID)
                  ?.label || user.amClassID,
              pmClassID:
                pmClassOptions.find((option) => option.value === user.pmClassID)
                  ?.label || user.pmClassID,
            }))
          : {
              ...resultData,
              roleID:
                rolesOptions.find(
                  (option) => option.value === resultData.roleID
                )?.label || resultData.roleID,
              amClassID:
                amClassOptions.find(
                  (option) => option.value === resultData.amClassID
                )?.label || resultData.amClassID,
              pmClassID:
                pmClassOptions.find(
                  (option) => option.value === resultData.pmClassID
                )?.label || resultData.pmClassID,
            };

        setResponse(transformedData);
        setData(resultData);
      } else {
        setResponse("You don't have a registered account.");
      }
    } catch (error) {
      console.error("Error fetching from post method:", error);
    }
  };

  const handleSendEmail = async (values: any) => {
    try {
      await axiosInstance.post("/api/User/SendEmail", values);
    } catch (error) {
      console.error("Error fetching from Email method:", error);
    }
  };

  return (
    <MainLayouts>
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl p-8">
          <div className="text-center mb-8">
            <img
              src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-4.0.3"
              alt="Medical Expo Logo"
              className="h-16 w-16 mx-auto mb-4 rounded-full"
            />
            <h2 className="text-2xl font-bold text-gray-900">
              Medical Expo 2025
            </h2>
          </div>

          {!response && (
            <UseFormikForm
              initialValues={{ email: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid email address")
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    "Email format is incorrect"
                  )
                  .required("Email is required"),
              })}
              onSubmit={handleEmailResponse}
            >
              {(formik, handleSubmit) => (
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className="relative">
                    <InputFieldComponent
                      label="Enter Your Email"
                      type="email"
                      name="email"
                      onChange={(event) =>
                        formik.setFieldValue("email", event.target.value)
                      }
                      labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                      onBlur={formik.onBlur}
                    />
                    <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                      <MdEmail />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-red-500 font-semibold">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full flex justify-center items-center py-3 px-4 text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                  >
                    <FiSend className="mr-2" />
                    Submit
                  </button>
                </form>
              )}
            </UseFormikForm>
          )}

          {response && (
            <div className="mt-6 p-4">
              {typeof response === "string" ? (
                <div className="bg-red-50 rounded-md p-4">
                  <div className="flex items-center">
                    <FaXmark className="text-red-500 mr-2" />
                    <p className="text-red-800">{response}</p>
                  </div>
                </div>
              ) : (
                <dl>
                  {Object.keys(response)
                    .filter((key) => key !== "year" && key !== "id")
                    .map((key, index) => (
                      <div
                        key={key}
                        className={`${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                      >
                        {key === "qrCodeBase64String" ? (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              {displayNameMap[key] ||
                                key
                                  .replace(/([a-z])([A-Z])/g, "$1 $2")
                                  .replace(/^./, (str) => str.toUpperCase())}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <div>
                                <img
                                  src={`data:image/png;base64,${response[key]}`}
                                  alt="Base64 Image"
                                  className="max-w-full border border-gray-300 p-2 bg-teal-700"
                                />
                              </div>
                            </dd>
                          </>
                        ) : (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              {displayNameMap[key] ||
                                key
                                  .replace(/([a-z])([A-Z])/g, "$1 $2")
                                  .replace(/^./, (str) => str.toUpperCase())}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {typeof response[key] === "object"
                                ? JSON.stringify(response[key])
                                : response[key]}
                            </dd>
                          </>
                        )}
                      </div>
                    ))}
                </dl>
              )}
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  onClick={() => handleSendEmail(data)}
                  className="flex justify-center items-center py-3 px-4 text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                >
                  <FiSend className="mr-2" />
                  Send Mail
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayouts>
  );
};

export default VerifyMail;
