export const RoutesPath = {
  HOME: "/",
  ABOUT_US: "/about-us",
  SPONSORS: "/sponsors",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  LOGIN: "/login",
  REGISTRATION: "/register",
  MANAGE_USER: "/user",
  VERIFY_EMAIL: "/verify",
  CONTACT_DATA: "/contact-data",
  AM_CLASS: "/amClass",
  PM_CLASS: "/pmClass",
  FRONT_DESK: "/front-desk-attendance",
  A_CLASS: "/a-class-attendance",
  B_CLASS: "/b-class-attendance",
  ATTENDANCE_DATA: "/attendance",
  SURVEY: "/survey",
  ADD_USER: "/addUser",
  SLIDESHOW: "Slideshow",
};
